import * as React from "react";
import { css } from "@emotion/react";
import { FlightDateBlock } from "main/javascripts/components/flight/FlightDateBlock";
import { FlightDatePickerBlock } from "main/javascripts/components/flight/FlightDatePickerBlock";
import { FlightOriginBlock } from "./FlightOriginBlock";
import { IColorStyle } from "../form/Label";
import { FlightDestinationBlock } from "./FlightDestinationBlock";
import { useSelector } from "react-redux";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";
import { Suggestion } from "main/javascripts/types/suggestion";
import { event } from "main/javascripts/utils/googleTagManagerUtil";
import { GoogleTagManagerEventTypes } from "main/javascripts/constants/GoogleTagManagerEventTypes";

interface IProps {
  fetchFlightOriginDestinationSuggestions: any;
  itineraryGroup?: any;
  defaultOrigins?: Suggestion[];
  defaultDestinations?: Suggestion[];
  useDatePicker?: boolean;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
}

export const FlightOneWayBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    fetchFlightOriginDestinationSuggestions,
    itineraryGroup,
    defaultOrigins,
    defaultDestinations,
    useDatePicker,
    labelColorStyleKey,
    borderColorStyleKey,
  } = props;

  // TODO: 旧reducerをそのまま使用
  const breakpoint: any = useSelector<any>(
    (state) => state.breakpointReducer.breakpoint
  );

  const onOriginSelect = (suggestion) => {
    event(GoogleTagManagerEventTypes.origin, {
      origin_id: suggestion.id,
      origin_type: suggestion.destinationType,
      origin: suggestion.longName,
    });
  };

  const onDestinationSelect = (suggestion) => {
    event(GoogleTagManagerEventTypes.origin, {
      destination_id: suggestion.id,
      destination_type: suggestion.destinationType,
      destination: suggestion.longName,
    });
  };

  const onDateChange = (departureDate) => {
    if (departureDate) {
      event(GoogleTagManagerEventTypes.date, {
        start_date: departureDate,
      });
    }
  };

  return (
    <>
      <div css={firstBlockStyle}>
        <FlightOriginBlock
          fetchFlightOriginDestinationSuggestions={
            fetchFlightOriginDestinationSuggestions
          }
          defaultOrigins={defaultOrigins}
          suggestOptions={{
            included_code: "JP",
          }} // 国内のみ表示
          itineraryGroup={itineraryGroup}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          onSelect={onOriginSelect}
        />
        <FlightDestinationBlock
          fetchFlightOriginDestinationSuggestions={
            fetchFlightOriginDestinationSuggestions
          }
          defaultDestinations={defaultDestinations}
          suggestOptions={{
            excluded_code: "JP",
          }} // 海外のみ表示
          itineraryGroup={itineraryGroup}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          onSelect={onDestinationSelect}
        />
        {useDatePicker && breakpoint ? (
          <FlightDatePickerBlock
            itineraryGroup={itineraryGroup}
            labelColorStyleKey={labelColorStyleKey}
            borderColorStyleKey={borderColorStyleKey}
            onChange={onDateChange}
          />
        ) : (
          <FlightDateBlock
            itineraryGroup={itineraryGroup}
            labelColorStyleKey={labelColorStyleKey}
            borderColorStyleKey={borderColorStyleKey}
            onChange={onDateChange}
          />
        )}
      </div>
    </>
  );
};

const firstBlockStyle = css`
  ${moreThanBreakpoint("tablet")} {
    display: flex;
  }
`;
