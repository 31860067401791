import * as React from "react";
import { css } from "@emotion/react";
import { FlightRoundTripDateBlock } from "main/javascripts/components/flight/FlightRoundTripDateBlock";
import { FlightRoundTripDatePickerBlock } from "main/javascripts/components/flight/FlightRoundTripDatePickerBlock";
import { FlightOriginBlock } from "./FlightOriginBlock";
import { FlightDestinationBlock } from "./FlightDestinationBlock";
import { useSelector } from "react-redux";
import { IColorStyle } from "main/javascripts/components/form/Label";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";
import { Suggestion } from "main/javascripts/types/suggestion";
import { event } from "main/javascripts/utils/googleTagManagerUtil";
import { GoogleTagManagerEventTypes } from "main/javascripts/constants/GoogleTagManagerEventTypes";

interface IProps {
  fetchFlightOriginDestinationSuggestions: any;
  defaultOrigins?: Suggestion[];
  defaultDestinations?: Suggestion[];
  destinationSuggestOptions: {
    excluded_code: string;
  };
  useDatePicker?: boolean;
  searchType?: string;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  disableStopBodyScrolling?: boolean;
}

export const FlightRoundTripBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    fetchFlightOriginDestinationSuggestions,
    defaultOrigins,
    defaultDestinations,
    destinationSuggestOptions,
    useDatePicker,
    searchType,
    labelColorStyleKey,
    borderColorStyleKey,
    disableStopBodyScrolling,
  } = props;

  // TODO: 旧reducerをそのまま使用
  const breakpoint: any = useSelector<any>(
    (state) => state.breakpointReducer.breakpoint
  );

  const onOriginSelect = (suggestion) => {
    event(GoogleTagManagerEventTypes.origin, {
      origin_id: suggestion.id,
      origin_type: suggestion.destinationType,
      origin: suggestion.longName,
    });
  };

  const onDestinationSelect = (suggestion) => {
    event(GoogleTagManagerEventTypes.origin, {
      destination_id: suggestion.id,
      destination_type: suggestion.destinationType,
      destination: suggestion.longName,
    });
  };

  const onDateChange = (departureDate, returnDate) => {
    if (departureDate && returnDate) {
      event(GoogleTagManagerEventTypes.date, {
        start_date: departureDate,
        end_date: returnDate,
      });
    }
  };

  return (
    <>
      <div css={firstBlockStyle}>
        <FlightOriginBlock
          fetchFlightOriginDestinationSuggestions={
            fetchFlightOriginDestinationSuggestions
          }
          defaultOrigins={defaultOrigins}
          suggestOptions={{
            included_code: "JP",
          }} // 国内のみ表示
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          disableStopBodyScrolling={disableStopBodyScrolling}
          onSelect={onOriginSelect}
        />
        <FlightDestinationBlock
          fetchFlightOriginDestinationSuggestions={
            fetchFlightOriginDestinationSuggestions
          }
          defaultDestinations={defaultDestinations}
          suggestOptions={destinationSuggestOptions}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          disableStopBodyScrolling={disableStopBodyScrolling}
          onSelect={onDestinationSelect}
        />
      </div>
      {useDatePicker && breakpoint ? (
        <FlightRoundTripDatePickerBlock
          searchType={searchType}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          disableStopBodyScrolling={disableStopBodyScrolling}
          onChange={onDateChange}
        />
      ) : (
        <FlightRoundTripDateBlock
          searchType={searchType}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          onChange={onDateChange}
        />
      )}
    </>
  );
};

const firstBlockStyle = css`
  ${moreThanBreakpoint("tablet")} {
    display: flex;
  }
`;
