import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FlightOneWayBlock } from "main/javascripts/components/flight/FlightOneWayBlock";
import { FlightRoundTripBlock } from "main/javascripts/components/flight/FlightRoundTripBlock";
import { FlightMultiCityListBlock } from "main/javascripts/components/flight/FlightMultiCityListBlock";
import { FlightTypes } from "main/javascripts/constants/FlightTypes";
import { IColorStyle } from "../form/Label";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";
import { Suggestion } from "../../types/suggestion";

export interface IProps {
  // styles: any;
  fetchFlightOriginDestinationSuggestions: any;
  defaultOrigins?: Suggestion[];
  defaultDestinations?: Suggestion[];
  destinationSuggestOptions: any;
  useDatePicker?: boolean;
  searchType?: string;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  disableStopBodyScrolling?: boolean;
}

export const FlightOriginDestinationDate: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    // styles,
    fetchFlightOriginDestinationSuggestions,
    defaultOrigins,
    defaultDestinations,
    destinationSuggestOptions,
    useDatePicker,
    searchType,
    labelColorStyleKey,
    borderColorStyleKey,
    disableStopBodyScrolling,
  } = props;

  const { control } = useFormContext();
  const flightTypeValue = useWatch({
    control,
    name: "flight_type",
  });

  const renderItinerary: (flightType: string, props: any) => JSX.Element = (
    flightType: string,
    props: any
  ) => {
    switch (flightType) {
      case FlightTypes.roundTrip:
        return (
          <FlightRoundTripBlock
            {...props}
            defaultOrigins={defaultOrigins}
            defaultDestinations={defaultDestinations}
            destinationSuggestOptions={{
              excluded_code: "JP",
            }}
            useDatePicker={useDatePicker}
            searchType={searchType}
            labelColorStyleKey={labelColorStyleKey}
            borderColorStyleKey={borderColorStyleKey}
            disableStopBodyScrolling={disableStopBodyScrolling}
          />
        );
      case FlightTypes.multiCity: {
        return (
          <FlightMultiCityListBlock
            {...props}
            defaultOrigins={defaultOrigins}
            defaultDestinations={defaultDestinations}
            useDatePicker={useDatePicker}
            labelColorStyleKey={labelColorStyleKey}
            borderColorStyleKey={borderColorStyleKey}
            disableStopBodyScrolling={disableStopBodyScrolling}
          />
        );
      }
      default:
        return (
          <FlightOneWayBlock
            {...props}
            defaultOrigins={defaultOrigins}
            defaultDestinations={defaultDestinations}
            useDatePicker={useDatePicker}
            labelColorStyleKey={labelColorStyleKey}
            borderColorStyleKey={borderColorStyleKey}
            disableStopBodyScrolling={disableStopBodyScrolling}
          />
        );
    }
  };

  return (
    <>
      {renderItinerary(flightTypeValue, {
        fetchFlightOriginDestinationSuggestions:
          fetchFlightOriginDestinationSuggestions,
        destinationSuggestOptions,
        // styles: styles,
      })}
    </>
  );
};
