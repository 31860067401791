import React, { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { FlightMultiCityBlock } from "main/javascripts/components/flight/FlightMultiCityBlock";
import { IColorStyle } from "../form/Label";
import { Button } from "../button/Button";
import { space } from "../../styles/base/spaceStyle";
import { moreThanBreakpoint } from "../../styles/base/responsiveStyle";
import { IBorderColorStyle } from "../../styles/base/formStyle";
import { Suggestion } from "../../types/suggestion";
import { event } from "main/javascripts/utils/googleTagManagerUtil";
import { GoogleTagManagerEventTypes } from "main/javascripts/constants/GoogleTagManagerEventTypes";

interface IProps {
  fetchFlightOriginDestinationSuggestions: any;
  defaultOrigins?: Suggestion[];
  defaultDestinations?: Suggestion[];
  useDatePicker?: boolean;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
}

const minItineraries = 2;
const maxItineraries = 6;

export const FlightMultiCityListBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    fetchFlightOriginDestinationSuggestions,
    defaultOrigins,
    defaultDestinations,
    useDatePicker,
    labelColorStyleKey,
    borderColorStyleKey,
  } = props;

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "itineraries",
  });
  const itinerariesValue =
    useWatch({
      control,
      name: "itineraries",
    }) || [];

  const itinerariesRef = useRef(itinerariesValue);
  itinerariesRef.current = itinerariesValue;
  const selectCountRef = useRef(0);

  useEffect(() => {
    if (selectCountRef.current === 0) {
      return;
    }
    event(GoogleTagManagerEventTypes.itineraries, {
      itineraries: itinerariesValue,
    });
  }, [selectCountRef.current]);

  const addItinerary = () => {
    const prevDestination: string =
      itinerariesValue[itinerariesValue.length - 1].destination;
    const prevDestinationId: string =
      itinerariesValue[itinerariesValue.length - 1].destination_id;
    const prevDestinationType: string =
      itinerariesValue[itinerariesValue.length - 1].destination_type;
    append({
      origin: prevDestination ? prevDestination : null,
      origin_id: prevDestinationId ? prevDestinationId : null,
      origin_type: prevDestinationType ? prevDestinationType : null,
    });
  };

  const removeItinerary = () => {
    remove(itinerariesValue.length - 1);
  };

  const addItineraryButton: any = {
    onClick: addItinerary,
    text: "+ 区間を追加",
  };

  const removeItineraryButton: any = {
    onClick: removeItinerary,
    text: "- 区間を削除",
  };

  const onSelect = () => {
    // この時点ではitinerariesValueがまだ更新されていないため
    // アップデートフラグを更新する
    selectCountRef.current = selectCountRef.current + 1;
  };

  const renderItineraryFields: () => JSX.Element = () => {
    return (
      <>
        {fields.map((field, index) => {
          const itinerary: any | undefined = itinerariesValue[index];
          const itineraryGroup = `itineraries.${index}`;
          if (!itinerary) {
            return <React.Fragment key={itineraryGroup} />;
          }

          return (
            <FlightMultiCityBlock
              key={field.id}
              fetchFlightOriginDestinationSuggestions={
                fetchFlightOriginDestinationSuggestions
              }
              itineraryGroup={itineraryGroup}
              index={index}
              defaultOrigins={defaultOrigins}
              defaultDestinations={defaultDestinations}
              useDatePicker={useDatePicker}
              labelColorStyleKey={labelColorStyleKey}
              borderColorStyleKey={borderColorStyleKey}
              onSelect={onSelect}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      {renderItineraryFields()}
      <div css={buttonsBlockStyle}>
        {itinerariesValue.length > minItineraries ? (
          <div css={buttonBlockStyle}>
            <Button
              type="button"
              styleKey="buttonRemove"
              onClick={removeItineraryButton.onClick}
            >
              {removeItineraryButton.text}
            </Button>
          </div>
        ) : (
          ""
        )}
        {itinerariesValue.length < maxItineraries ? (
          <div css={buttonBlockStyle}>
            <Button
              type="button"
              styleKey="buttonAdd"
              onClick={addItineraryButton.onClick}
            >
              {addItineraryButton.text}
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

const buttonsBlockStyle = css`
  display: flex;
  padding: ${space.atom2x} 0;
  justify-content: flex-end;
  ${moreThanBreakpoint("tablet")} {
    padding: ${space.atom2x} 0 0;
  }
`;
const buttonBlockStyle = css`
  width: calc(50% - ${space.atom2x});
  padding: 0 ${space.atom} ${space.atom};
  ${moreThanBreakpoint("tablet")} {
    width: 120px;
    padding: 0 ${space.atom};
  }
`;
