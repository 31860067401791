import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";
import { get, useFormContext, useWatch } from "react-hook-form";
import { RadioButtonBlock } from "main/javascripts/components/form/RadioButtonBlock";
import { ILabelStyle } from "main/javascripts/components/form/RadioButton";

export interface IProps {
  labelStyleKey?: keyof ILabelStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  errors?: any;
}

export const FlightTypeBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { labelStyleKey, borderColorStyleKey, errors } = props;
  const { t } = useTranslation(["constant"]);

  const { control, clearErrors } = useFormContext();
  const fieldName = "flight_type";
  const checkedValue = useWatch({
    control,
    name: fieldName,
  });

  useEffect(() => {
    clearErrors();
  }, [checkedValue]);

  const flightTypes = [
    { text: t("flightType.oneWay"), value: "one_way" },
    { text: t("flightType.roundTrip"), value: "round_trip" },
    { text: t("flightType.multiCity"), value: "multi_city" },
  ];

  return (
    <div>
      <RadioButtonBlock
        namespace="flight"
        checkedValue={checkedValue}
        options={flightTypes}
        input={{ name: fieldName }}
        error={get(errors, fieldName)}
        labelStyleKey={labelStyleKey}
        borderColorStyleKey={borderColorStyleKey}
        displayStyle="flex"
        widthStyle="auto"
      />
    </div>
  );
};
