import * as React from "react";
import { css } from "@emotion/react";
import { useFormContext, useWatch } from "react-hook-form";
import { addDays, format, subDays, differenceInCalendarDays } from "date-fns";
import {
  Calendar,
  FromDateType,
  ToDateType,
} from "main/javascripts/components/molecules/Calendar";
import { DepartureDateInput } from "main/javascripts/components/flight/form/DepartureDateInput";
import { ReturnDateInput } from "main/javascripts/components/flight/form/ReturnDateInput";
import { searchCategories } from "main/javascripts/constants/SearchCategories";
import { parseDate } from "main/javascripts/utils/DateUtil";
import { useRef, useState } from "react";
import { IColorStyle } from "../form/Label";
import { space } from "../../styles/base/spaceStyle";
import { FormNamespaces } from "../../constants/FormConstants";
import { IBorderColorStyle } from "../../styles/base/formStyle";

export interface IProps {
  // styles?: any;
  searchType?: string;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  onChange?: any;
}

export const FlightRoundTripDateBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { searchType, labelColorStyleKey, borderColorStyleKey, onChange } =
    props;

  const { setValue, control, trigger } = useFormContext();
  const [departureDateValue, returnDateValue] = useWatch({
    control,
    name: ["departure_date", "return_date"],
  });

  const [displayedCalendar, setDisplayedCalendar] = useState(false);
  const [dateType, setDateType] = useState(null);

  const inputDepartureDateRef = useRef<any>();
  const inputReturnDateRef = useRef<any>();

  const onClickDepartureDate: () => void = () => {
    // inputのonFocusを呼ばないとFormが更新されな
    setDisplayedCalendar(true);
    setDateType(FromDateType);
  };

  const onClickReturnDate: () => void = () => {
    // inputのonFocusを呼ばないとFormが更新されない
    setDisplayedCalendar(true);
    setDateType(ToDateType);
  };

  const hideCalendar: () => void = () => {
    setDisplayedCalendar(false);
  };

  const isInvalidDate = (startDate: Date, endDate: Date): boolean => {
    if (searchType === searchCategories.flight) {
      return differenceInCalendarDays(endDate, startDate) < 0;
    }
    return differenceInCalendarDays(endDate, startDate) <= 0;
  };

  const onSelectDeparture: (date: Date) => void = (date: Date) => {
    let departureDateString = "";
    let returnDateString = returnDateValue;
    const parsed_return_date = parseDate(returnDateValue);
    // toDateがnullや選択日以前であれば、toDateをfromDateの1日後とする
    if (returnDateValue === null || isInvalidDate(date, parsed_return_date)) {
      returnDateString = format(addDays(date, 1), "yyyy/LL/dd");
      setValue("return_date", returnDateString);
    }

    // 選択したらものはfromDateとする
    departureDateString = format(date, "yyyy/LL/dd");
    setValue("departure_date", departureDateString);
    void trigger(["departure_date"]);

    if (onChange) {
      onChange(departureDateString, returnDateString);
    }
  };

  const onSelectReturn: (date: Date) => void = (date: Date) => {
    let departureDateString = departureDateValue;
    let returnDateString = "";
    const parsed_departure_date = parseDate(departureDateValue);
    // fromDateがnullや選択日以後の場合は、fromDateはtoDateの1日前とする
    if (
      departureDateValue === null ||
      isInvalidDate(parsed_departure_date, date)
    ) {
      departureDateString = format(subDays(date, 1), "yyyy/LL/dd");
      setValue("departure_date", departureDateString);
    }

    returnDateString = format(date, "yyyy/LL/dd");
    setValue("return_date", returnDateString);
    void trigger(["return_date"]);

    if (onChange) {
      onChange(departureDateString, returnDateString);
    }
  };

  const onSelect: any =
    dateType === FromDateType ? onSelectDeparture : onSelectReturn;

  // TODO
  // const calClassNames: string[] = [
  //   FlightRoundTripDateBlockStyle.calendarBlock,
  // ];

  // if (dateType === ToDateType) {
  //   calClassNames.push(FlightRoundTripDateBlockStyle.calendarRight);
  // }

  const fromDate: Date | null = departureDateValue
    ? parseDate(departureDateValue)
    : null;
  const toDate: Date | null = returnDateValue
    ? parseDate(returnDateValue)
    : null;

  return (
    <div css={blockStyle}>
      <div css={inputBlockStyle}>
        <DepartureDateInput
          namespace={FormNamespaces.flight}
          isActive={dateType === FromDateType}
          onClick={onClickDepartureDate}
          inputBlockRef={inputDepartureDateRef}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          // styles={{
          //   labelStyle: labelStyle,
          // }}
        />
        <ReturnDateInput
          namespace={FormNamespaces.flight}
          isActive={dateType === ToDateType}
          onClick={onClickReturnDate}
          inputBlockRef={inputReturnDateRef}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          // styles={{
          //   labelStyle: labelStyle,
          // }}
        />
      </div>
      <Calendar
        fromDate={fromDate}
        toDate={toDate}
        dateType={dateType}
        onSelect={onSelect}
        hideCalendar={hideCalendar}
        displayedCalendar={displayedCalendar}
        // styles={calClassNames} // TODO: styleどうする？
      />
    </div>
  );
};

const blockStyle = css`
  position: relative;
  flex: 1;
`;
const inputBlockStyle = css`
  display: flex;
  padding: ${space.atom} 0 0;
`;
