import * as React from "react";
import { css } from "@emotion/react";
import { FlightDateBlock } from "main/javascripts/components/flight/FlightDateBlock";
import { FlightDatePickerBlock } from "main/javascripts/components/flight/FlightDatePickerBlock";
import { FlightOriginBlock } from "./FlightOriginBlock";
import { FlightDestinationBlock } from "./FlightDestinationBlock";
import { IColorStyle } from "main/javascripts/components/form/Label";
import { useSelector } from "react-redux";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { borderColor } from "main/javascripts/styles/base/colorStyle";
import { IBorderColorStyle } from "../../styles/base/formStyle";
import { Suggestion } from "../../types/suggestion";

interface IProps {
  fetchFlightOriginDestinationSuggestions: any;
  itineraryGroup?: any;
  index: any;
  defaultOrigins?: Suggestion[];
  defaultDestinations?: Suggestion[];
  useDatePicker?: boolean;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  onSelect?: any;
}

export const FlightMultiCityBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    fetchFlightOriginDestinationSuggestions,
    itineraryGroup,
    index,
    defaultOrigins,
    defaultDestinations,
    useDatePicker,
    labelColorStyleKey,
    borderColorStyleKey,
    onSelect,
  } = props;

  // TODO: 旧reducerをそのまま使用
  const breakpoint: any = useSelector<any>(
    (state) => state.breakpointReducer.breakpoint
  );

  return (
    <>
      <div css={firstBlockStyle}>
        <FlightOriginBlock
          fetchFlightOriginDestinationSuggestions={
            fetchFlightOriginDestinationSuggestions
          }
          defaultOrigins={defaultOrigins}
          itineraryGroup={itineraryGroup}
          index={index}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          onSelect={onSelect}
        />
        <FlightDestinationBlock
          fetchFlightOriginDestinationSuggestions={
            fetchFlightOriginDestinationSuggestions
          }
          defaultDestinations={defaultDestinations}
          itineraryGroup={itineraryGroup}
          index={index}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          onSelect={onSelect}
        />
        {useDatePicker && breakpoint ? (
          <FlightDatePickerBlock
            itineraryGroup={itineraryGroup}
            index={index}
            labelColorStyleKey={labelColorStyleKey}
            borderColorStyleKey={borderColorStyleKey}
            onChange={onSelect}
          />
        ) : (
          <FlightDateBlock
            itineraryGroup={itineraryGroup}
            index={index}
            labelColorStyleKey={labelColorStyleKey}
            borderColorStyleKey={borderColorStyleKey}
            onChange={onSelect}
          />
        )}
      </div>
    </>
  );
};

const firstBlockStyle = css`
  margin-bottom: ${space.atom};
  &:after {
    content: "";
    display: block;
    padding-top: ${space.atom2x};
    margin: 0 ${space.atom};
    border-bottom: 1px solid ${borderColor.secondaryLightColor};
  }
  ${moreThanBreakpoint("tablet")} {
    display: flex;
    padding-bottom: 0;
    &:after {
      content: none;
    }
  }
`;
