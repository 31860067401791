import React, { useRef, useState } from "react";
import { css } from "@emotion/react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { format, addDays, isAfter, isDate } from "date-fns";
import { useTranslation } from "react-i18next";
import { DepartureDateInput } from "main/javascripts/components/flight/form/DepartureDateInput";
import { isPhone } from "main/javascripts/styles/base/responsiveStyle";
import { FullScreenModalDialog } from "main/javascripts/components/molecules/Modal/FullScreenModalDialog";
import { ScrollableDatePicker } from "main/javascripts/components/molecules/DatePicker";
import { MAX_SEARCH_HOTEL_DAYS } from "main/javascripts/constants/HotelConstants";
import { HorizontalDatePicker } from "main/javascripts/components/molecules/DatePicker/HorizontalDatePicker";
import { parseDate } from "main/javascripts/utils/DateUtil";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { IColorStyle } from "main/javascripts/components/form/Label";
import { FormNamespaces } from "main/javascripts/constants/FormConstants";
import { IBorderColorStyle } from "../../styles/base/formStyle";

export interface IProps {
  itineraryGroup?: any;
  index?: number;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  onChange?: any;
}

export const FlightDatePickerBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    itineraryGroup,
    index,
    labelColorStyleKey = "tabletLight",
    borderColorStyleKey,
    onChange,
  } = props;
  const { t } = useTranslation(["component"]);

  // TODO: 旧reducerをそのまま使用
  const breakpoint: any = useSelector<any>(
    (state) => state.breakpointReducer.breakpoint
  );

  const { setValue, control, trigger } = useFormContext();
  const [departureDateValue, returnDateValue, itinerariesValue] = useWatch({
    control,
    name: ["departure_date", "return_date", "itineraries"],
  });

  const [displayedPicker, setDisplayedPicker] = useState(false);

  const inputDepartureDateRef = useRef<any>();

  const val: (value: string) => string = (value: string) => {
    if (itineraryGroup) {
      return `${itineraryGroup}.${value}`;
    }
    return value;
  };

  const onClickDepartureDate: () => void = () => {
    setDisplayedPicker(true);
  };

  const onComplete: (startDate: Date | null, endDate: Date | null) => void = (
    startDate: Date | null,
    _: Date | null
  ) => {
    if (startDate) {
      const departureDateString = format(startDate, "yyyy/LL/dd");
      setValue(val("departure_date"), departureDateString);
      void trigger([val("departure_date")]);
      changeNextDateLoop(startDate, Number(index));
      // 片道から往復に切り替えた際に不正な範囲にならないよう調整
      if (!itineraryGroup) {
        const returnDateString: string = returnDateValue;
        if (returnDateString) {
          const returnDate: Date = new Date(returnDateString);
          if (returnDate && !isAfter(returnDate, startDate)) {
            setValue(
              "return_date",
              format(addDays(startDate, 1), "yyyy/LL/dd")
            );
            void trigger([val("return_date")]);
          }
        }
      }
      if (onChange) {
        // 周遊のitineraryには対応していないので注意
        onChange(departureDateString);
      }
    }
    hidePicker();
  };

  const hidePicker: () => void = () => {
    setDisplayedPicker(false);
  };

  const changeNextDateLoop = (date: Date, index: number): void => {
    if (
      itinerariesValue &&
      itinerariesValue.length > index + 1 &&
      itinerariesValue[index + 1].departure_date
    ) {
      const departureDateObject: Date | string = parseDate(
        itinerariesValue[index + 1].departure_date
      );
      const nextIndexDateTime: number = isDate(departureDateObject)
        ? Number(format(departureDateObject, "T"))
        : 0;
      if (date.getTime() >= nextIndexDateTime) {
        changeNextDate(date, index);
      }
    }
  };

  const changeNextDate = (date: Date, newIndex: number): void => {
    const name = `${itineraryGroup.replace(
      index,
      newIndex + 1
    )}.departure_date`;
    const newDate: string = format(
      date.setDate(date.getDate() + 1),
      "yyyy/LL/dd"
    );
    setValue(name, newDate);
    void trigger(name);
    changeNextDateLoop(date, newIndex + 1);
  };

  // const { blockStyle, labelStyle } = styles;

  // 再レンダリングの負荷が高い場合はメモ化検討する
  let disableDate: Date = new Date();
  let fromDate: Date | null = departureDateValue
    ? parseDate(departureDateValue)
    : null;
  if (index && itinerariesValue && itinerariesValue.length > 0) {
    if (
      itinerariesValue[index - 1] &&
      itinerariesValue[index - 1].departure_date
    ) {
      disableDate = addDays(
        parseDate(itinerariesValue[index - 1].departure_date),
        1
      );
    }
    if (itinerariesValue[index].departure_date) {
      fromDate = parseDate(itinerariesValue[index].departure_date);
    }
  }

  return (
    <div css={blockStyle}>
      <div css={inputBlockStyle}>
        <DepartureDateInput
          namespace={FormNamespaces.flight}
          name={val("departure_date")}
          isActive={false}
          onClick={onClickDepartureDate}
          inputBlockRef={inputDepartureDateRef}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
        />
      </div>
      {isPhone(breakpoint) ? (
        <FullScreenModalDialog
          displayed={displayedPicker}
          closeHandler={hidePicker}
          colorMode="light"
        >
          <ScrollableDatePicker
            fromDate={fromDate}
            onComplete={onComplete}
            numberOfMonths={12}
            maxDays={MAX_SEARCH_HOTEL_DAYS}
            outOfRangeMessage={t("search.calendarMaxDaysMessage")}
            startDateLabel={t("label:common.departureDate")}
            endDateLabel={t("label:common.returnDate")}
            isSingleSelection={true}
            disableDate={disableDate}
          />
        </FullScreenModalDialog>
      ) : (
        <HorizontalDatePicker
          fromDate={fromDate}
          onUpdate={onComplete}
          hidePicker={hidePicker}
          numberOfMonths={12}
          maxDays={MAX_SEARCH_HOTEL_DAYS}
          outOfRangeMessage={t("search.calendarMaxDaysMessage")}
          startDateLabel={t("label:common.departureDate")}
          endDateLabel={t("label:common.returnDate")}
          displayedPicker={displayedPicker}
          isSingleSelection={true}
          disableDate={disableDate}
          fromInputRef={inputDepartureDateRef.current}
        />
      )}
    </div>
  );
};

const blockStyle = css`
  position: relative;
  flex: 1;
`;
const inputBlockStyle = css`
  display: flex;
  padding: ${space.atom} 0 0;
`;
