import React, { useRef, useState } from "react";
import { css } from "@emotion/react";
import { useFormContext, useWatch } from "react-hook-form";
import { format, addDays, isDate } from "date-fns";
import {
  Calendar,
  FromDateType,
} from "main/javascripts/components/molecules/Calendar";
import { DepartureDateInput } from "main/javascripts/components/flight/form/DepartureDateInput";
import { parseDate } from "main/javascripts/utils/DateUtil";
import { IColorStyle } from "main/javascripts/components/form/Label";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { FormNamespaces } from "main/javascripts/constants/FormConstants";
import { IBorderColorStyle } from "../../styles/base/formStyle";

export interface IProps {
  itineraryGroup?: any;
  index?: number;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  onChange?: any;
}

export const FlightDateBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    itineraryGroup,
    index,
    labelColorStyleKey,
    borderColorStyleKey,
    onChange,
  } = props;

  const { setValue, control, trigger } = useFormContext();
  const [departureDateValue, itinerariesValue] = useWatch({
    control,
    name: ["departure_date", "itineraries"],
  });

  const [displayedCalendar, setDisplayedCalendar] = useState(false);

  const inputDepartureDateRef = useRef<any>();

  const val: (value: string) => string = (value: string) => {
    if (itineraryGroup) {
      return `${itineraryGroup}.${value}`;
    }
    return value;
  };

  const onClickDepartureDate: () => void = () => {
    // inputのonFocusを呼ばないとFormが更新されな
    setDisplayedCalendar(true);
  };

  const hideCalendar: () => void = () => {
    setDisplayedCalendar(false);
  };

  const changeNextDateLoop = (date: Date, index: number): void => {
    if (
      itinerariesValue &&
      itinerariesValue.length > index + 1 &&
      itinerariesValue[index + 1].departure_date
    ) {
      const departureDateObject: Date | string = parseDate(
        itinerariesValue[index + 1].departure_date
      );
      const nextIndexDateTime: number = isDate(departureDateObject)
        ? Number(format(departureDateObject, "T"))
        : 0;
      if (date.getTime() >= nextIndexDateTime) {
        changeNextDate(date, index);
      }
    }
  };

  const changeNextDate = (date: Date, newIndex: number): void => {
    const name = `${itineraryGroup.replace(
      index,
      newIndex + 1
    )}.departure_date`;
    const newDate: string = format(
      date.setDate(date.getDate() + 1),
      "yyyy/LL/dd"
    );
    setValue(name, newDate);
    void trigger(name);
    changeNextDateLoop(date, newIndex + 1);
  };

  const onSelectDeparture: (date: Date) => void = (date: Date) => {
    // 選択したらものはfromDateとする
    const departureDateString = format(date, "yyyy/LL/dd");
    setValue(val("departure_date"), departureDateString);
    void trigger(val("departure_date"));

    changeNextDateLoop(date, Number(index));

    if (onChange) {
      // 周遊のitineraryには対応していないので注意
      onChange(departureDateString);
    }
  };

  // const { blockStyle, labelStyle } = styles;
  const onSelect: any = onSelectDeparture;

  // const calClassNames: string[] = [
  //   FlightDateBlockStyle.calendarBlock,
  //   FlightDateBlockStyle.calendarRight,
  // ];

  let disableDate: Date = new Date();
  let fromDate: Date | null = departureDateValue
    ? parseDate(departureDateValue)
    : new Date();
  if (index && itinerariesValue && itinerariesValue.length > 0) {
    if (
      itinerariesValue[index - 1] &&
      itinerariesValue[index - 1].departure_date
    ) {
      disableDate = addDays(
        parseDate(itinerariesValue[index - 1].departure_date),
        1
      );
    }
    if (itinerariesValue[index].departure_date) {
      fromDate = parseDate(itinerariesValue[index].departure_date);
    }
  }

  return (
    <div css={blockStyle}>
      <div css={inputBlockStyle}>
        <DepartureDateInput
          namespace={FormNamespaces.flight}
          name={val("departure_date")}
          isActive={true}
          onClick={onClickDepartureDate}
          inputBlockRef={inputDepartureDateRef}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          // styles={{
          //   labelStyle: labelStyle,
          // }}
        />
      </div>
      <Calendar
        fromDate={fromDate}
        toDate={fromDate}
        dateType={FromDateType}
        onSelect={onSelect}
        hideCalendar={hideCalendar}
        displayedCalendar={displayedCalendar}
        // styles={calClassNames} // TODO: styleどうする？
        arrowStyleOverride="right"
        disableDate={disableDate}
      />
    </div>
  );
};

const blockStyle = css`
  position: relative;
  min-width: 9rem;
`;
const inputBlockStyle = css`
  display: flex;
  padding: ${space.atom} 0 0;
`;
// const calendarBlockStyle = css`
//   min-width: 300px;
//   position: absolute;
//   top: 88px;
//   z-index: 10;
//   box-shadow: 0 6px 30px rgba(40, 40, 40, 0.1);
// `;
// const calendarRightStyle = css`
//   left: auto;
//   right: 0;
// `;
