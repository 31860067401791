import React, { useRef, useState } from "react";
import { css } from "@emotion/react";
import { useFormContext, useWatch } from "react-hook-form";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { DepartureDateInput } from "main/javascripts/components/flight/form/DepartureDateInput";
import { ReturnDateInput } from "main/javascripts/components/flight/form/ReturnDateInput";
import { isPhone } from "main/javascripts/styles/base/responsiveStyle";
import { FullScreenModalDialog } from "main/javascripts/components/molecules/Modal/FullScreenModalDialog";
import { ScrollableDatePicker } from "main/javascripts/components/molecules/DatePicker";
import { HorizontalDatePicker } from "main/javascripts/components/molecules/DatePicker/HorizontalDatePicker";
import { MAX_SEARCH_HOTEL_DAYS } from "main/javascripts/constants/HotelConstants";
import { DateTypes } from "main/javascripts/constants/DateTypes";
import { searchCategories } from "main/javascripts/constants/SearchCategories";
import { parseDate } from "main/javascripts/utils/DateUtil";
import { useSelector } from "react-redux";
import { IColorStyle } from "main/javascripts/components/form/Label";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";
import { FormNamespaces } from "../../constants/FormConstants";

export interface IProps {
  searchType?: string;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  disableStopBodyScrolling?: boolean;
  onChange?: any;
}

export const FlightRoundTripDatePickerBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    searchType,
    labelColorStyleKey = "tabletLight",
    borderColorStyleKey,
    disableStopBodyScrolling,
    onChange,
  } = props;
  const { t } = useTranslation(["component"]);

  // TODO: 旧reducerをそのまま使用
  const breakpoint: any = useSelector<any>(
    (state) => state.breakpointReducer.breakpoint
  );

  const { setValue, control, trigger } = useFormContext();
  const [departureDateValue, returnDateValue] = useWatch({
    control,
    name: ["departure_date", "return_date"],
  });

  const [displayedPicker, setDisplayedPicker] = useState(false);
  const [pickerDateType, setPickerDateType] = useState(null);

  const inputDepartureDateRef = useRef<any>();
  const inputReturnDateRef = useRef<any>();

  const onClickDepartureDate: () => void = () => {
    setDisplayedPicker(true);
    setPickerDateType(DateTypes.fromDate);
  };

  const onClickReturnDate: () => void = () => {
    setDisplayedPicker(true);
    setPickerDateType(DateTypes.toDate);
  };

  const onComplete: (startDate: Date | null, endDate: Date | null) => void = (
    startDate: Date | null,
    endDate: Date | null
  ) => {
    let departureDateString = "";
    let returnDateString = "";
    if (startDate) {
      departureDateString = format(startDate, "yyyy/LL/dd");
      setValue("departure_date", departureDateString);
      void trigger(["departure_date"]);
    }
    if (endDate) {
      returnDateString = format(endDate, "yyyy/LL/dd");
      setValue("return_date", returnDateString);
      void trigger(["return_date"]);
    }
    if (onChange) {
      onChange(departureDateString, returnDateString);
    }
    hidePicker();
  };

  const onUpdate: (
    startDate: Date | null,
    endDate: Date | null,
    isEndStep: boolean
  ) => void = (
    startDate: Date | null,
    endDate: Date | null,
    isEndStep: boolean
  ) => {
    let departureDateString = "";
    let returnDateString = "";
    if (startDate) {
      departureDateString = format(startDate, "yyyy/LL/dd");
      setValue("departure_date", departureDateString);
      void trigger(["departure_date"]);
    }
    if (endDate) {
      returnDateString = format(endDate, "yyyy/LL/dd");
      setValue("return_date", returnDateString);
      void trigger(["return_date"]);
    }
    if (!isEndStep) {
      setPickerDateType(DateTypes.toDate);
    }
    if (onChange) {
      onChange(departureDateString, returnDateString);
    }
  };

  const hidePicker: () => void = () => {
    setDisplayedPicker(false);
    setPickerDateType(null);
  };

  // const { blockStyle, labelStyle } = styles;

  const fromDate: Date | null = departureDateValue
    ? parseDate(departureDateValue)
    : null;
  const toDate: Date | null = returnDateValue
    ? parseDate(returnDateValue)
    : null;
  const canSelectSameDate: boolean = searchType === searchCategories.flight;

  return (
    <div css={blockStyle}>
      <div css={inputBlockStyle}>
        <DepartureDateInput
          namespace={FormNamespaces.flight}
          isActive={pickerDateType === DateTypes.fromDate}
          onClick={onClickDepartureDate}
          inputBlockRef={inputDepartureDateRef}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
        />
        <ReturnDateInput
          namespace={FormNamespaces.flight}
          isActive={pickerDateType === DateTypes.toDate}
          onClick={onClickReturnDate}
          inputBlockRef={inputReturnDateRef}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
        />
      </div>
      {isPhone(breakpoint) ? (
        <FullScreenModalDialog
          displayed={displayedPicker}
          closeHandler={hidePicker}
          colorMode="light"
          disableStopBodyScrolling={disableStopBodyScrolling}
        >
          <ScrollableDatePicker
            fromDate={fromDate}
            toDate={toDate}
            onComplete={onComplete}
            numberOfMonths={12}
            maxDays={MAX_SEARCH_HOTEL_DAYS}
            outOfRangeMessage={t("search.calendarMaxDaysMessage")}
            startDateLabel={t("label:common.departureDate")}
            endDateLabel={t("label:common.returnDate")}
            dateType={pickerDateType}
            canSelectSameDate={canSelectSameDate}
          />
        </FullScreenModalDialog>
      ) : (
        <HorizontalDatePicker
          fromDate={fromDate}
          toDate={toDate}
          onUpdate={onUpdate}
          hidePicker={hidePicker}
          numberOfMonths={12}
          maxDays={MAX_SEARCH_HOTEL_DAYS}
          outOfRangeMessage={t("search.calendarMaxDaysMessage")}
          startDateLabel={t("label:common.departureDate")}
          endDateLabel={t("label:common.returnDate")}
          displayedPicker={displayedPicker}
          dateType={pickerDateType}
          fromInputRef={inputDepartureDateRef.current}
          toInputRef={inputReturnDateRef.current}
          canSelectSameDate={canSelectSameDate}
        />
      )}
    </div>
  );
};

const blockStyle = css`
  position: relative;
  flex: 1;
`;

const inputBlockStyle = css`
  display: flex;
  padding: ${space.atom} 0 0;
`;
