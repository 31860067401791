import React from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { SelectBlock } from "main/javascripts/components/form/SelectBlock";
import { IColorStyle } from "main/javascripts/components/form/Label";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";
import { FormNamespaces } from "../../../constants/FormConstants";
import { get } from "react-hook-form";

export interface IProps {
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  errors?: any;
}

const CabinClassTypes: { value: string; label: string }[] = [
  { value: "Y", label: "economy" },
  { value: "W", label: "economyPremium" },
  { value: "C", label: "business" },
  { value: "F", label: "first" },
];

export const CabinClassBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { labelColorStyleKey, borderColorStyleKey, errors } = props;
  const { t } = useTranslation(["constant", "label"]);

  const optionList: JSX.Element[] = [];
  // const { innerBlockStyle = {}, labelStyle } = styles;

  CabinClassTypes.map((option: { value: string; label: string }) => {
    optionList.push(
      <option key={option.value} value={option.value}>
        {t(`flightClass.${option.label}`)}
      </option>
    );
  });

  const fieldName = "cabin_class";

  return (
    <div css={flightClassBlockStyle}>
      <SelectBlock
        namespace={FormNamespaces.flight}
        label={{
          namespace: "common",
          label: t("label:common.bookingClass"),
          colorStyleKey: labelColorStyleKey,
        }}
        select={{
          name: fieldName,
          children: optionList,
        }}
        error={get(errors, fieldName)}
        borderColorStyleKey={borderColorStyleKey}
      />
    </div>
  );
};

const flightClassBlockStyle = css`
  padding: ${space.atom} 0 0;
`;
// const flightClassInputBlockStyle = css`
//   width: 50%;
//   min-width: 200px;
// `;
