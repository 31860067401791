import React from "react";
import { useTranslation } from "react-i18next";
import { CalendarDateBlock } from "main/javascripts/components/form/CalendarDateBlock";
import { IColorStyle } from "main/javascripts/components/form/Label";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";
import { get, useFormContext, useWatch } from "react-hook-form";

export interface IProps {
  namespace: string;
  name?: string;
  isActive: boolean;
  onClick(): void;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  inputBlockRef?: any;
}

export const DepartureDateInput: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    namespace,
    name,
    isActive,
    onClick,
    labelColorStyleKey,
    borderColorStyleKey,
    inputBlockRef,
  } = props;
  const { t } = useTranslation(["label"]);

  const fieldName = name || "departure_date";

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const value = useWatch({ control, name: fieldName });

  const attrs: any = {
    name: fieldName,
    namespace: namespace,
    label: {
      label: t("label:common.departureDate"),
      colorStyleKey: labelColorStyleKey,
    },
    date: value,
    onClick: onClick,
    isActive: isActive,
    error: get(errors, fieldName),
    inputBlockRef: inputBlockRef,
    borderColorStyleKey: borderColorStyleKey,
  };

  return <CalendarDateBlock {...attrs} />;
};
